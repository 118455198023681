import { useContext } from 'react'
import { FormInputSearch } from '@wiz/components'
import { useIntl } from '@wiz/intl'
import LabelList from '@/hoc/LabelList'
import CategorieList from '@/hoc/CategorieList'
import UnitList from '@/hoc/UnitList'
import BusinessTypeList from '@/hoc/BusinessTypeList'
import InputModeList from '@/hoc/InputModeList'
import Select from '@/components/Form/Select'
import SelectTwin from '@/components/Form/SelectTwin'
import ListFilters from '@/components/Form/ListFilters'
import { AppContext } from '@/pages/context'

export default function Filters () {
  const intl = useIntl()
  const { filters, onFilter } = useContext(AppContext)
  return (
    <ListFilters
      filters={filters}
      onFilter={onFilter}
    >
      {({ filters, onFilter }) => (
        <>
          <div className="me-1 mb-2" style={{}}>
            <Select
              placeholder={intl.t('form.fields.labelsPlaceholder')}
              multiselect
              ListSource={LabelList}
              displayCount={1}
              value={filters.labels}
              onChange={labels => onFilter({ labels })}
              className="form-select-sm"
            />
          </div>

          <div className="mx-1 mb-2" style={{}}>
            <Select
              placeholder={intl.t('form.fields.categoryPlaceholder')}
              ListSource={CategorieList}
              value={filters.categoryId}
              onChange={categoryId => onFilter({ categoryId })}
              className="form-select-sm"
            />
          </div>

          <div className="me-1 mb-2" style={{}}>
            <Select
              placeholder={intl.t('form.fields.unitPlaceholder')}
              ListSource={UnitList}
              value={filters.unitId}
              onChange={unitId => onFilter({ unitId })}
              className="form-select-sm"
            />
          </div>
          {/*
          <div className="me-1 mb-2" style={{}}>
            <SelectTwin
              displayHardware
              placeholder={intl.t('form.fields.twinPlaceholder')}
              value={filters.twinId}
              filters={{
                types: [],
                withSensors: false,
              }}
              onChange={twinId => onFilter({ twinId })}
              className="form-select-sm"
            />
          </div> */}

          <div className="px-md-2 mb-2 col-md-4 col-lg-3">
            <Select
              placeholder={intl.t('sensors.form.fields.businessTypePlaceholder')}
              ListSource={BusinessTypeList}
              value={filters.businessType || ''}
              onChange={businessType => onFilter({ businessType })}
              className="form-select-sm"
            />
          </div>

          <div className="px-md-2 mb-2 col-md-4 col-lg-3">
            <Select
              placeholder={intl.t('sensors.form.fields.inputModePlaceholder')}
              ListSource={InputModeList}
              value={filters.inputMode || ''}
              onChange={inputMode => onFilter({ inputMode })}
              className="form-select-sm"
            />
          </div>
        </>
      )}
    </ListFilters>
  )
}
