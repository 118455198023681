import {
  useEffect, useCallback, useMemo, useState,
} from 'react'
import {
  useRouter, useLocationQuery,
} from '@/router'
import cx from 'classnames'
import { useQuery } from '@tanstack/react-query'
import { consts, duration } from '@wiz/utils'
import Icon from '@/shared/icon'
import { withObservables } from '@wiz/components'
import { wizataApi } from '@/api'
import { useIntl } from '@wiz/intl'
import { dbProvider } from '@wiz/store'
import { useGlobalExecute } from '@/context/GlobalExecuteProvider'
import Chart from './chart'
import Filters from './filters'
import SingleValue from './singleValue'
import { entities } from './consts'
import classes from './index.module.css'
import useAppContext from '../../hooks/useAppContext'
import { populateTriggerName } from '../../utils/triggers'

const experimentsMap = {
  [consts.ExperimentFilterType.isNull]: true,
  [consts.ExperimentFilterType.isNotNull]: false,
  [consts.ExperimentFilterType.all]: 'all',
}

const options = {
  avg: 'Avg',
  sum: 'Sum',
  max: 'Max',
  min: 'Min',
}
// filters have '_' for unknown reason, so we exclude it too
const excludeFilters = [ 'sortDir', 'sortBy', 'page', 'pageSize', 'dateTo', 'dateFrom', 'search', 'current', '_' ]

const ChartsDrawer = ({
  filters,
  valuesList = [],
  sequence,
  entity,
  itemsList,
  twinsList,
  usersList,
  categoriesList,
  unitsList,
  includeChildren,
}) => {
  const [ isOpen, setOpen ] = useState(false)
  const [ selectedChart, setSelectedChart ] = useState(consts.PanelChartType.pie)
  const [ selectedGroupBy, setSelectedGroupBy ] = useState('')
  const [ selectedValue, setSelectedValue ] = useState('count')
  const [ selectedOption, setSelectedOption ] = useState(Object.keys(options)[0])

  const { contextTwinId } = useGlobalExecute()
  const { onFilter } = useAppContext()

  const intl = useIntl()
  const router = useRouter()
  const query = useLocationQuery()

  const {
    data: groupedChartData, refetch, isLoading, isFetching,
  } = useQuery({
    queryKey: [ 'groupedChartData', entity + selectedGroupBy + selectedChart + contextTwinId ],
    queryFn: () => {
      const groupSummaries = selectedValue === 'count' ? [] : [{
        type: options[selectedOption],
        propertyName: selectedValue,
      }]

      const filtersOptions = {
        dateFrom: {
          propertyName: 'CreatedDate',
          type: consts.FilterType.UnixTimestamp,
          operationType: consts.FilterOperationType.Between,
          start: filters.dateFrom,
          end: filters.dateTo,
        },
        businessType: {
          propertyName: 'businessType',
          type: consts.FilterType.Enum,
          operationType: consts.FilterOperationType.Equals,
          value: filters.businessType,
        },
        inputMode: {
          propertyName: 'inputMode',
          type: consts.FilterType.Enum,
          operationType: consts.FilterOperationType.Equals,
          value: filters.inputMode,
        },
        dashboardType: {
          propertyName: 'type',
          type: consts.FilterType.Enum,
          operationType: consts.FilterOperationType.Equals,
          value: filters.dashboardType,
        },
        // labelId: {
        //   name: 'labelId',
        //   type: consts.FilterType.Guid,
        //   operationType: consts.FilterOperationType.Equals,
        //   value: filters.labelId,
        // },
        pipelineId: {
          propertyName: 'pipelineId',
          type: filters.pipelineId === 'null' ? consts.FilterType.Guid : consts.FilterType.Enum,
          operationType: consts.FilterOperationType.Equals,
          canBeNull: filters.pipelineId === 'null' ? true : !filters.pipelineId,
          ...(filters.pipelineId && filters.pipelineId !== 'null') ? { value: filters.pipelineId } : {},
        },
        executionTriggerId: {
          propertyName: 'executionTriggerId',
          type: filters.executionTriggerId === 'null' ? consts.FilterType.Guid : consts.FilterType.Enum,
          operationType: consts.FilterOperationType.Equals,
          canBeNull: filters.executionTriggerId === 'null' ? true : !filters.executionTriggerId,
          ...(filters.executionTriggerId && filters.executionTriggerId !== 'null') ?
            { value: filters.executionTriggerId } : {},
        },
        templateId: {
          propertyName: 'templateId',
          type: filters.templateId === 'null' ? consts.FilterType.Guid : consts.FilterType.Enum,
          operationType: consts.FilterOperationType.Equals,
          canBeNull: filters.templateId === 'null' ? true : !filters.templateId,
          ...(filters.templateId && filters.templateId !== 'null') ? { value: filters.templateId } : {},
        },
        experiments: {
          propertyName: 'experimentId',
          type: consts.FilterType.Guid,
          operationType: consts.FilterOperationType.Equals,
          value: null,
          canBeNull: experimentsMap[filters.experiments],
        },
        status: {
          propertyName: 'status',
          type: consts.FilterType.Enum,
          operationType: consts.FilterOperationType.Equals,
          value: filters.status,
        },
        categoryId: {
          propertyName: 'categoryId',
          type: filters.categoryId === 'null' ? consts.FilterType.Guid : consts.FilterType.Enum,
          operationType: consts.FilterOperationType.Equals,
          canBeNull: filters.categoryId === 'null' ? true : !filters.categoryId,
          ...(filters.categoryId && filters.categoryId !== 'null') ? { value: filters.categoryId } : {},
        },
        unitId: {
          propertyName: 'unitId',
          type: filters.unitId === 'null' ? consts.FilterType.Guid : consts.FilterType.Enum,
          operationType: consts.FilterOperationType.Equals,
          canBeNull: filters.unitId === 'null' ? true : !filters.unitId,
          ...(filters.unitId && filters.unitId !== 'null') ? { value: filters.unitId } : {},
        },
      }

      const nextFilters = []

      Object.entries(filters).forEach(([ key, value ]) => {
        if (key === entities.experiments) {
          if (value !== 'all') {
            nextFilters.push(filtersOptions[key])
          }
        } else if (value && filtersOptions[key]) {
          nextFilters.push(filtersOptions[key])
        }
      })

      const complexFilters = {}

      if (contextTwinId) {
        complexFilters.twinId = contextTwinId
        if (includeChildren) {
          complexFilters.includeChildren = true
        }
      }
      // if (selectedGroupBy === 'labelIds') {
      //   complexFilters.labelIds = []
      // }
      if (filters.labelIds || filters.labels) {
        complexFilters.labelIds = filters.labels || filters.labelId
      }

      return wizataApi.entities.getGroupedList(entities[entity], {
        groups: [{
          propertyName: selectedGroupBy,
        }],
        groupSummaries,
        filters: nextFilters,
        ...complexFilters,
      })
    },
    enabled: !!selectedGroupBy,
    refetchOnWindowFocus: false,
    retry: false,
  })

  const availableFilters = useMemo(() => {
    const nextFilters = Object.entries(filters).reduce((acc, [ key, val ]) => {
      if (!excludeFilters.includes(key)) {
        acc[key] = val
      }
      return acc
    }, {})
    return nextFilters
  }, [ filters ])

  const groupByList = useMemo(() => {
    const uniqueValues = { experiments: consts.ExperimentFilterType.all }

    return Object.entries(availableFilters).map(([ key, value ]) => {
      if (!value ||
        (value && uniqueValues[key] === value) || key === 'twinId') {
        return {
          id: key,
          value: key,
          name: key.replaceAll('Id', ''),
        }
      }
      return undefined
    }).filter(i => !!i)
  }, [ availableFilters ])

  const option = useMemo(() => {
    if (!groupedChartData?.totalCount) {
      return null
    }
    const list = { ...itemsList }

    if (twinsList) {
      list.twinId = twinsList
    }
    if (usersList) {
      list.ownerId = usersList
    }
    if (categoriesList) {
      list.categoryId = categoriesList
    }
    if (unitsList) {
      list.unitId = unitsList
    }

    let arrayList = list[selectedGroupBy]

    if (arrayList && !Array.isArray(arrayList)) {
      arrayList = arrayList.items || []
    }
    const prepared = groupedChartData.items.map((item) => {
      const next = {
        name: item[selectedGroupBy] || 'Empty',
        value: item[selectedValue] ?? item.TotalCount,
        group: selectedGroupBy,
        ...(!item[selectedGroupBy] ? { id: 'null' } : {}),
      }
      if (list[selectedGroupBy]) {
        const found = arrayList.find(i => i.id === item[selectedGroupBy])
        if (found) {
          if (selectedGroupBy === 'executionTriggerId') {
            const nextTrigger = populateTriggerName(found)
            next.name = nextTrigger.name || 'Empty'
            if (!nextTrigger.name) {
              next.id = 'null'
            }
          } else {
            next.name = found.name || found.key || 'Empty'
            if (!found.name && !found.key) {
              next.id = 'null'
            }
          }
          next.id = found.id || null
        }
      }
      return { ...next }
    })
    return {
      // title: {
      //   text: entity + selectedGroupBy + selectedChart + contextTwinId,
      // subtext: 'Fake Data',
      //   left: 'center',
      // },

      ...(selectedChart !== consts.PanelChartType.pie) && {
        xAxis: {
          // type: selectedGroupBy,
          data: prepared.map(item => item.name),
        },
        yAxis: {
          type: 'value',
        },
      },
      tooltip: {
        trigger: 'item',
        // formatter: '{b} : {c} ({d}%)',

      },
      legend: {
        orient: 'vertical',
        left: 'left',
        inactiveColor: '#4b4c53',
        textStyle: {
          color: '#9B9BA2',
        },
        show: !(prepared.length > 10),
      },
      color: selectedGroupBy === 'status' ?
        prepared.map(item => consts.ExecutionStatusColorsScheme[item.name]) :
        consts.ColorsScheme,
      itemStyle: {
        normal: {
          label: {
            show: false,
          },
          labelLine: {
            show: false,
          },
        },
      },
      series: [
        {
          // name: selectedGroupBy,
          type: selectedChart,
          label: { show: false },
          radius: '50%',
          data: prepared,
          tooltip: {
            valueFormatter: (value) => {
              if (selectedValue === 'count') {
                return value
              }
              return duration(value, { sep: ' ' })
            },
          },
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)',
            },
          },
          custom: {
            filters,
          },
        },
      ],
    }
  }, [
    selectedChart,
    selectedGroupBy,
    groupedChartData,
    selectedValue,
    itemsList,
    usersList,
    twinsList,
    categoriesList,
    unitsList,
    filters,
  ])

  const handleFilter = useCallback(({ chart, groupBy, value }) => {
    if (chart) {
      setSelectedChart(chart)
    } else if (groupBy) {
      setSelectedGroupBy(groupBy)
    } else if (value) {
      setSelectedValue(value)
    }
  }, [ ])

  const singleValue = useMemo(() => {
    if (groupByList.length) {
      return null
    }
    if (selectedValue === 'count') {
      return groupedChartData?.items[0]?.TotalCount || groupedChartData?.totalCount
    }

    if (groupedChartData?.items?.length === 1) {
      return groupedChartData?.items[0][selectedValue]
    }

    return 0
  }, [ groupByList, groupedChartData, selectedValue ])

  const handleSequence = useCallback((nextGroup) => {
    const list = nextGroup || groupByList
    if (!list?.length) {
      setSelectedGroupBy(sequence[sequence.length - 1].name)
    }
    for (const item of sequence) {
      // const fulfilledList = [...list, ...]
      const foundSequence = list.find(group => group.id === item.name)
      if (foundSequence) {
        setSelectedGroupBy(foundSequence.id)
        if (!nextGroup || (nextGroup && nextGroup.length < groupByList.length)) {
          setSelectedChart(item.chart)
        }
        break
      }
    }
  }, [ groupByList, sequence ])

  const handleChartClick = useCallback(({ data }, { series }) => {
    const currentGroup = groupByList.find(gr => gr.id === data.group)
    const isLastItem = !currentGroup && groupByList.length === 1
    const nextGroupList = groupByList.filter((gr) => {
      if (series?.[0]?.custom?.filters?.[gr.id]) {
        return false
      }
      if (data.group === gr.id) {
        return false
      }
      return true
    })

    if (data.group === 'twinId' && series?.[0]?.data?.length === 1) {
      handleSequence(nextGroupList)
    } else if (filters[currentGroup.id] !== data.name && data.group !== 'twinId') {
      onFilter({ [currentGroup.id]: data.id || data.name })
      handleSequence(nextGroupList)
    } else if (isLastItem) {
      const item = groupByList[0]
      // onFilter({[selectedGroupBy]: })
    }
    router.push({ query: { ...query, page: null } })
    return null
  }, [ filters, groupByList, onFilter, handleSequence, query, router ])

  useEffect(() => {
    if (!selectedGroupBy) {
      handleSequence()
    }
  }, [ selectedGroupBy, handleSequence ])
  useEffect(() => {
    if (groupedChartData && !!selectedGroupBy && !isFetching) {
      refetch()
    }
  }, [
    selectedGroupBy,
    selectedOption,
    selectedValue,
    // filters.dateTo,
    // filters.dateFrom,
    // filters.status,
    // filters.pipelineId,
    // filters.experiments,
    // filters.executionTriggerId,
    contextTwinId,
    // filters.templateId,
    // filters.unitId,
    ...(filters ? Object.keys(filters).map(filter => filters[filter]) : []),
    filters.businessType,
  ])

  if (!groupedChartData?.items?.length && !isLoading) {
    return null
  }

  return (
    <div
      className={classes.root}
      style={isOpen ? { width: '541px', flexDirection: 'column' } : { height: '50%' }}
    >
      <div
        className={classes.icon}
        style={isOpen ? { margin: '16px 16px 0' } : {}}
        onClick={() => setOpen(!isOpen)}
        aria-hidden
      >
        <Icon name={isOpen ? 'faChevronRight' : 'faChevronLeft'} size="sm" />
        {isOpen ? null : <span>Charts</span>}
      </div>
      {isOpen ? (
        <div className="d-flex flex-fill flex-column min-h-0 pb-md-3 px-3 align-items-center">
          <div className={classes.text}>
            <p className="fw-bold m-0">{consts.ExperimentFilterName[filters.experiments]}</p>
            {singleValue ? null : (
              <p className="m-0">
                By&nbsp;
                {selectedGroupBy.replaceAll('Id', '')}
              </p>
            )}
          </div>
          <Filters
            chart={selectedChart}
            groupBy={selectedGroupBy}
            value={selectedValue}
            groupByList={groupByList}
            valuesList={valuesList}
            onFilter={handleFilter}
            isSingleValue={singleValue}
          />
          <div className="text-center">
            <span className="pb-1">{intl.t(`form.fields.${selectedValue}`)}</span>
            <div className="d-flex flex-fill min-w-0 align-items-center flex-nowrap flex-shrink-0">
              {Object.keys(options).map(opt => (
                <button
                  key={opt}
                  type="button"
                  className={cx('btn btn-sm btn-text py-0 px-1 me-1 px-3', {
                    'btn-secondary': selectedOption === opt,
                    'btn-fill-secondary': selectedOption !== opt,
                  })}
                  disabled={selectedValue === 'count'}
                  onClick={() => setSelectedOption(opt)}
                >
                  {opt}
                </button>
              ))}
            </div>
          </div>
          {!groupByList?.length ? (
            <SingleValue
              value={singleValue}
              pure={selectedValue === 'count'}
            />
          ) : (
            <Chart
              data={option}
              onChartClick={handleChartClick}
              isLoading={isLoading}
              filters={filters}
            />
          )}
        </div>
      ) : null}
    </div>
  )
}

const enhanceProps = withObservables([ ], () => ({
  twinsList: dbProvider.database.collections.get('twins').query().observeWithColumns([ 'updated_at' ]),
  usersList: dbProvider.database.collections.get('users').query().observeWithColumns([ 'updated_at' ]),
  categoriesList: dbProvider.database.collections.get('categories').query().observeWithColumns([ 'updated_at' ]),
  unitsList: dbProvider.database.collections.get('units').query().observeWithColumns([ 'updated_at' ]),
}))

export default enhanceProps(ChartsDrawer)
